import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import { useTranslation } from "react-i18next";
import 'leaflet/dist/leaflet.css';
import searoute from 'searoute-js';
import Puertos from "../Datos/Puertos/Puertos";
import imagen_background from "../Images/Agentes/introimg2.webp";

const SeaRouteMap = () => {
  const { t } = useTranslation();
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [currentRoute, setCurrentRoute] = useState([]); // For the animated line
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [selectedPortIndex, setSelectedPortIndex] = useState(null);
  const [estimatedDays, setEstimatedDays] = useState(null); // Nuevo estado para la estimación de días
  const mapRef = useRef();
  const currentRouteRef = useRef([]); // Ref to store the current route while animating

  // Velocidad promedio de un barco en nudos
  const averageSpeedKnots = 20; // 20 nudos (promedio)

  // Fórmula para convertir nudos a kilómetros por hora (1 nudo = 1.852 km/h)
  const averageSpeedKmPerHour = averageSpeedKnots * 1.852;

  // Función para calcular la distancia entre dos puntos usando la fórmula de Haversine
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radio de la Tierra en km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distancia en km
    return distance;
  };

  // Función para calcular el tiempo de viaje estimado en días
  const calculateTravelTime = (coordinates) => {
    let totalDistanceKm = 0;

    // Calcular la distancia total de la ruta sumando las distancias entre puntos consecutivos
    for (let i = 1; i < coordinates.length; i++) {
      const [lat1, lng1] = coordinates[i - 1];
      const [lat2, lng2] = coordinates[i];

      const distance = calculateDistance(lat1, lng1, lat2, lng2);
      totalDistanceKm += distance;
    }

    // Calcular el tiempo estimado en horas y convertir a días
    const estimatedTimeHours = totalDistanceKm / averageSpeedKmPerHour;
    const estimatedDays = estimatedTimeHours / 24;

    return estimatedDays;
  };

  // Function to calculate the route and animate the line
  const calculateRoute = () => {
    if (selectedPortIndex === null || selectedPortIndex === "none") {
      alert("Por favor, seleccione un puerto de origen");
      return;
    }

    const selectedPort = Puertos[selectedPortIndex];

    // Ensure valid coordinates
    if (!selectedPort.latitud || !selectedPort.longitud) {
      console.error("Invalid coordinates for the selected port.");
      return;
    }

    const origin = {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [selectedPort.longitud, selectedPort.latitud]  // Selected port's coordinates
      }
    };

    const destination = {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [-69.634766, 18.423232]  // Puerto Caucedo, República Dominicana
      }
    };

    try {
      const route = searoute(origin, destination);

      if (route && route.geometry && route.geometry.coordinates) {
        // Add checks to avoid undefined lat/lng
        const coordinates = route.geometry.coordinates.map(([lng, lat]) => {
          if (lat === undefined || lng === undefined) {
            console.error('Invalid coordinates:', { lat, lng });
            return null; // Return null for invalid coordinates
          }
          return [lat, lng]; // Ensure correct lat/lng order
        }).filter(Boolean); // Filter out invalid coordinates

        if (coordinates.length > 0) {
          const centerLat = (coordinates[0][0] + coordinates[coordinates.length - 1][0]) / 2;
          const centerLng = (coordinates[0][1] + coordinates[coordinates.length - 1][1]) / 2;

          mapRef.current.setView([centerLat, centerLng], 4);
          setRouteCoordinates(coordinates); // Store full route for animation
          animateLine(coordinates); // Start animation

          // Calcular y almacenar la estimación de días
          const days = calculateTravelTime(coordinates);
          setEstimatedDays(days);
        } else {
          console.error('No valid coordinates found');
        }
      } else {
        throw new Error('No se pudo obtener la ruta');
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Function to animate the polyline drawing
  const animateLine = (coordinates) => {
    if (!coordinates || coordinates.length === 0) return;  // Handle empty route

    let index = 1;
    currentRouteRef.current = [coordinates[0]]; // Start with the first point

    const interval = setInterval(() => {
      if (index < coordinates.length) {
        // Push the new point to the ref, not state
        currentRouteRef.current = [...currentRouteRef.current, coordinates[index]];
        index += 1;
      }

      // Stop the animation when we have added the last coordinate
      if (index >= coordinates.length) {
        clearInterval(interval);  // Clear the interval when the route is fully drawn
      }

      // Update the state in each interval to trigger rendering
      setCurrentRoute([...currentRouteRef.current]);
    }, 100); // Adjust the interval for faster/slower animation
  };

  const handlePortChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedPortIndex(selectedIndex);
    const port = Puertos[selectedIndex];

    // Validate the port data
    if (port && port.latitud !== undefined && port.longitud !== undefined) {
      setLatitude(port.latitud);
      setLongitude(port.longitud);
    } else {
      console.error("Invalid port data:", port);
    }

    // Reset previous route and estimation
    setRouteCoordinates([]);
    setCurrentRoute([]);
    setEstimatedDays(null);
  }

  return (
    <div className="w-full h-auto py-16">
      <div className="flex justify-center items-center bg-top w-full h-44 md:h-72 px-10 md:px-20 lg:px-30 xl:px-30 2xl:px-40 overflow-hidden" style={{ backgroundImage: `url(${imagen_background})`, backgroundSize: 'cover' }}>
        <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row items-center  justify-between w-full gap-y-4">
          <h1 className="text-white text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl font-semibold animate__animated animate__fadeInLeft">{t("Rutas")}</h1>
        </div>
      </div>

      <div className='flex flex-col items-center'>
        <div className="max-w-7xl grid md:grid-cols-3 gap-5 py-10">
          <div className="flex flex-col">
            <label className="text-2xl font-bold">{t("origin_country")}</label>
            <select
              className="p-2 rounded-md border border-slate-200 shadow-md focus:bg-white focus:outline-none w-56 h-12"
              onChange={handlePortChange}
              value={selectedPortIndex || "none"}
            >
              <option value="none" className="bg-white">Seleccione un puerto</option>
              {Puertos.map((puerto, index) => (
                <option key={index} value={index}>{puerto.nombre}</option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-2xl font-bold">{t("destiny")}</label>
            <div className="p-2 rounded-md border border-slate-200 shadow-md shadow-bluemunsell/25 focus:bg-white focus:outline-none w-56 h-12 flex items-center justify-center">
              <p className="bg-white">República Dominicana</p>
            </div>
          </div>

          <div className="flex items-end">
            <button
              className="bg-bluemunsell hover:bg-cyan-700 text-white w-44 h-12 rounded-md"
              onClick={calculateRoute}
            >
              {t("search")}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <div className="max-w-7xl rounded-md overflow-hidden z-0" style={{ height: '500px', width: '80%' }}>
          <MapContainer
            ref={mapRef}
            zoom={2}
            style={{ height: '100%', width: '100%' }}
            center={[0, 0]}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            {currentRoute.length > 0 && (
              <Polyline positions={currentRoute} color="blue" />
            )}
          </MapContainer>
        </div>
        {/* Mostrar la estimación de días si está disponible */}
        {estimatedDays !== null && (
          <div className="flex justify-center mt-5">
            <div className="max-w-7xl bg-white shadow-md rounded-md p-4">
              <p className="text-xl font-semibold">Tiempo estimado de viaje: {estimatedDays.toFixed(2)} días</p>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default SeaRouteMap;
