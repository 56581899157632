import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import ChainedBackend from "i18next-chained-backend";
import { initReactI18next } from "react-i18next";

const apiKey = "mbNX9vTX1N_hOApnLoolpw";
const i18nexusLoadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
const localLoadPath = '/locales/{{lng}}/{{ns}}.json';

const isBrowser = typeof window !== 'undefined'; 
const savedLanguage = isBrowser ? (localStorage.getItem('language') || 'es') : 'es';

i18next
  .use(ChainedBackend) 
  .use(initReactI18next) 
  .init({
    lng: savedLanguage,
    fallbackLng: "es",  
    ns: ["default"],
    defaultNS: "default",
    supportedLngs: ["es", "en"],
    backend: isBrowser ? {
      backends: [HttpBackend],  
      backendOptions: [
        { loadPath: localLoadPath }, 
        { loadPath: i18nexusLoadPath }  
      ]
    } : {},  
    interpolation: {
      escapeValue: false  
    },
    react: {
      useSuspense: false  
    }
  })
  .then(() => {
    if (isBrowser) {
    }
  })
  .catch((err) => {
    console.error('i18n initialization failed', err);
  });

if (isBrowser) {
  i18next.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng);
  });
}

export default i18next;
