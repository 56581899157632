import confotur from "../Images/Proyectos/CONFOTUR.jpg";
import energia1 from "../Images/Proyectos/Energia1.jpg";
import energia2 from "../Images/Proyectos/Energia2.jpg";
import epa from "../Images/Proyectos/Epa.png";
import tratado from "../Images/Proyectos/tratado.png";
import confotur1 from "../Images/Proyectos/Confotur.png";
import parquesoco from "../Images/Proyectos/Parquesoco.jpg";

const proyectosdatos = [
    {
        id: 1,
        title: "proyectos.card1.title",
        img1: energia2,
        subtitle1: "proyectos.card1.subtitle1",
        paragraph1: "proyectos.card1.paragraph1",
        img2: energia1,
        subtitle2: "proyectos.card1.subtitle2",
        paragraph2: "proyectos.card1.paragraph2",
        subtitle3: "proyectos.card1.subtitle3",
        paragraph3: "proyectos.card1.paragraph3",
        img4: parquesoco,
        subtitle4: "proyectos.card1.subtitle4",
        paragraph4: "proyectos.card1.paragraph4",
        subtitle5: "proyectos.card1.subtitle5",
        paragraph5: "proyectos.card1.paragraph5",
    },
    {
        id: 2,
        title: "proyectos.card2.title",
        img1: confotur,
        subtitle1: "proyectos.card2.subtitle1",
        paragraph1: "proyectos.card2.paragraph1",
        img2: confotur1,
        subtitle2: "proyectos.card2.subtitle2",
        paragraph2: "proyectos.card2.paragraph2",
        subtitle3: "proyectos.card2.subtitle3",
        paragraph3: "proyectos.card2.paragraph3",
    },
    {
        id: 3,
        title: "proyectos.card3.title",
        img1: tratado,
        subtitle1: "proyectos.card3.subtitle1",
        paragraph1: "proyectos.card3.paragraph1",
        img2: epa,
        subtitle2: "proyectos.card3.subtitle2",
        paragraph2: "proyectos.card3.paragraph2",
        subtitle3: "proyectos.card3.subtitle3",
        paragraph3: "proyectos.card3.paragraph3",
    },
];

export default proyectosdatos;
