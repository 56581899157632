import { Routes, Route, useLocation } from "react-router-dom";
import 'default-passive-events';
import Homepage from "./Pages/Home";
import NavBar from "./Components/Navbar";
import Nosotros from "./Pages/Nosotros";
import Servicios from "./Pages/Servicios";
import Agentes from "./Pages/Agentes";
import Noticias from "./Pages/Noticias";
import Rastreo from "./Pages/Rastreo";
import Footer from "./Components/Footer";
import ArticulosPagina from "./Components/Articulo";
import SeguridadyPrivacidad from "./Pages/PrivacidadySeguridad";
import { useEffect, useState } from "react";
import Contacto from "./Pages/Contacto";
import ScrollToTopButton from "./Components/ScrolltoTopButton";
import Terminos from "./Pages/Terminos";
import CentrodeAyuda from "./Pages/CentrodeAyuda";
import Soporte from "./Pages/Soporte";
import PreguntasFrecuentes from "./Pages/PreguntasFrecuentes";
import HighPerformance from "./Pages/HighPerformance";
import Proyectos from "./Pages/Proyectos";
import './i18n'; 
import ProyectoPlantilla1 from "./Components/Proyecto_plantilla_1";
import SeaRouteMapa from "./Pages/Rutas";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [activeLink, setActiveLink] = useState("");
  const [trackSearch, setTrackSearch] = useState('');
  const [shipName, setShipName] = useState('');

  useEffect(() => {
    // Verifica que el código se ejecute en el navegador
    if (typeof window !== 'undefined') {
      // Crear el primer script para Botpress
      const script1 = document.createElement('script');
      script1.src = 'https://cdn.botpress.cloud/webchat/v2.1/inject.js';
      script1.async = true;
      document.body.appendChild(script1);

      // Crear el segundo script para la configuración de Botpress
      const script2 = document.createElement('script');
      script2.src = 'https://mediafiles.botpress.cloud/0ccbe189-4b6d-4af3-b55c-420d27d5cc72/webchat/v2.1/config.js';
      script2.async = true;
      document.body.appendChild(script2);

      // Limpieza: eliminar los scripts cuando el componente se desmonte
      return () => {
        document.body.removeChild(script1);
        document.body.removeChild(script2);
      };
    }
  }, []);

  return (
    <>
      <NavBar activeLink={activeLink} setActiveLink={setActiveLink}/>
      <Routes>
        <Route path="/" element={
          <Homepage
            setTrackSearch={setTrackSearch}
            trackSearch={trackSearch}
            setShipName={setShipName}
          />
        } />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/agentes" element={<Agentes />} />
        <Route path="/noticias" element={<Noticias />} />
        <Route path="/rastreo" element={
          <Rastreo
            trackSearch={trackSearch}
            setTrackSearch={setTrackSearch}
            shipName={shipName}
            setShipName={setShipName}
          />
        } />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/centrodeayuda" element={<CentrodeAyuda />} />
        <Route path="/articulo/:oid" element={<ArticulosPagina />} />
        <Route path="/SeguridadyPrivacidad" element={<SeguridadyPrivacidad />} />
        <Route path="/terminos" element={<Terminos />} />
        <Route path="/soporte" element={<Soporte />} />
        <Route path="/preguntasfrecuentes" element={<PreguntasFrecuentes />} />
        <Route path="/highperformance" element={<HighPerformance />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/proyectos/:id" element={<ProyectoPlantilla1 />} />
        <Route path="/Rutas" element={<SeaRouteMapa />} /> 
      </Routes>
      {/* <Botondeasistencias/> */}
      <ScrollToTopButton />
      <ScrollToTop />
      <Footer setActiveLink={setActiveLink} />
    </>
  );
}

export default App;
