import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Array de idiomas con sus respectivas banderas y códigos
const languages = [
    {
        code: 'es-DO',
        label: 'Español',
        flag: '🇪🇸', // Emoji de la bandera de República Dominicana
    },
    {
        code: 'en',
        label: 'English',
        flag: '🇺🇸', // Emoji de la bandera de EE.UU.
    },

];

const LenguajeDropdown = () => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false); // Controla la visibilidad del dropdown
    const [selectedLang, setSelectedLang] = useState(languages.find(lang => lang.code === i18n.language) || languages[0]); // Idioma seleccionado
    const dropdownRef = useRef(null);

    // Función para alternar la visibilidad del dropdown
    const toggleDropdown = () => setIsOpen(!isOpen);

    // Función para seleccionar un idioma
    const selectLanguage = (lang) => {
        setSelectedLang(lang);
        i18n.changeLanguage(lang.code); // Cambiar el idioma en i18n
        setIsOpen(false);
    };

    // Cerrar el dropdown si se hace clic fuera de él
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left items-center justify-center" ref={dropdownRef}>
            <button
                onClick={toggleDropdown}
                className="inline-flex items-center justify-center px-4 text-sm font-medium text-gray-700 bg-transparent  focus:outline-none"
            >
                <span className="mr-2 text-xl pt-1">{selectedLang.flag}</span>
            </button>

            {isOpen && (
                <div
                    className="lg:absolute right-0 z-10 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    <div className="py-1">
                        {languages.map((lang) => (
                            <button
                                key={lang.code}
                                onClick={() => selectLanguage(lang)}
                                className={`${selectedLang.code === lang.code
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700'
                                    } flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900`}
                                role="menuitem"
                            >
                                <span className="mr-3 text-xl">{lang.flag}</span>
                                {lang.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LenguajeDropdown;
